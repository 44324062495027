import React from 'react';

const CustomForm = ({formData,products,specificProducts,addProduct,removeProduct,change,clear,save}) => {
    return(
        <form onSubmit={save}>
            <div className="row">
                <div className="input-field col s12">
                    <input id="comp_name" name='comp_name' type="text" value={formData.comp_name} onChange={change.bind(null,null)} required autoComplete='off' className="validate autocomplete" />
                    <label className={formData.comp_name !== '' ? 'active' : ''} htmlFor="comp_name">Company Name</label>
                </div>
                <div className="input-field col s12">
                    <input id="main_color" name="main_color" value={formData.main_color} onChange={change.bind(null,null)} type="text" required className="validate" />
                    <label className={formData.main_color !== '' ? 'active' : ''} htmlFor="main_color">Main Color</label>
                </div>
                <div className="input-field col s12" style={{marginTop:'2rem'}} >
                    <p>Choose Products</p>
                    {
                        products.length !== 0 ?
                        products.map((product,index) =>{
                            return (
                                    <div key={index} 
                                    id={'chip_'+product.product_id} 
                                    className='chip card IconChip'
                                    style={{backgroundColor:'#003453',color:'white',cursor:'pointer'}} 
                                    onClick={()=>addProduct(product)}>
                                        {product.product_name}
                                    </div>
                                    )
                        }) : <p>Products Not available!</p>
                    }
                </div>
                <div className="input-field col s12" style={{marginTop:'2rem'}}>
                    <ul className="collapsible">
                        {specificProducts.length !== 0 ?
                        specificProducts.map((product,index) => {
                            return (
                                <li key={product.product_id}>
                                    <div className="collapsible-header">
                                        {product.product_name}
                                        {!product.include && product.unique_identifier === ''?
                                            <button type='button' className='RemoveBtn btn-flat' onClick={() => removeProduct(product)}>
                                                Remove
                                            </button>:null}
                                    </div>
                                    <div className="collapsible-body">
                                        <div className='row'>
                                            <div className="input-field col s12" style={{marginBottom:'3rem'}}>
                                                <label>
                                                    <input id={product.product_type+'_include'} 
                                                    checked={product.include} 
                                                    name='include' 
                                                    onChange={change.bind(null,index)} type="checkbox" />
                                                    <span>Include</span>
                                                </label>
                                            </div>
                                            <div className="input-field col s12">
                                                <input id={product.product_type+'_unique_identifier'} name='unique_identifier' required value={product.unique_identifier} onChange={change.bind(null,index)} type="text" className="validate" />
                                                <label className={product.unique_identifier !== '' ? 'active' : ''} htmlFor={product.product_type+'_unique_identifier'}>Unique Identifier</label>
                                            </div>
                                            <div className="input-field col s12">
                                                <input id={product.product_type+'_icon_text'} name='icon_text' value={product.icon_text} onChange={change.bind(null,index)} type="text" className="validate" />
                                                <label className={product.icon_text ? 'active' : ''} htmlFor={product.product_type+'_icon_text'}>Icon Text</label>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        }) : <p>No Products added!</p>}
                    </ul>
                </div>
                <div className="input-field col s12">
                    <button className="btn waves-effect waves-light FormBtn" type="submit" name="save">Save
                        <i className="material-icons right">cloud_upload</i>
                    </button>
                    <button className="btn waves-effect waves-light FormBtn" onClick={clear} type="button" name="clear">Clear
                        <i className="material-icons right">clear_all</i>
                    </button>
                </div>               
            </div>
        </form>
    );
}

export default CustomForm;