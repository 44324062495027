import React from 'react';
import './scriptModal.css'

const ScriptModal = (props) => {
    return (
        <div id='breezetask-scripts' className="modal ScriptModal">
            <div className="modal-content">
            <h4>Breezetask Scripts</h4>
                <p id='scriptContent'>                   
                    {"<link href='https://breezetask.breezesuite.com/breezetask-scripts/static/css/breezetask.css' rel='stylesheet'>"}<br/>
                    {"<script>localStorage.setItem('bt_comp_id',"+ props.compId +")</script>"}<br/>
                    {"<script src='https://breezetask.breezesuite.com/breezetask-scripts/static/js/breezetask-run.js'></script>"} <br/>
                    {"<script src='https://breezetask.breezesuite.com/breezetask-scripts/static/js/breezetask-main.js'></script>"} <br/>
                    {"<script src='https://breezetask.breezesuite.com/breezetask-scripts/static/js/breezetask-chunk.js'></script>"}
                </p>
            </div>
            <div className="modal-footer">
                <button className="btn modal-close waves-effect">Ok</button>
            </div>
        </div>
    )
}

export default ScriptModal;
