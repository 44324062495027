import React from 'react';

import './report.css';
import CustomTable from '../customComponents/customTable/customTable';
import Spinner from '../customComponents/spinner/spinner';
import DonutChart from 'react-donut-chart';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'; 
import M from 'materialize-css';
import {Link} from 'react-router-dom';
import {cacheHeaders, basePath} from '../config';

const initialTable = [{
    clicks:1,
    impressions:1,
    cro:100
}];

const initialColumns = [
    {
      Header: "Clicks",
      accessor: "clicks"
    },
    {
      Header: "Impressions",
      accessor: "impressions"
    },
    {
      Header: "CRO (%)",
      accessor: "cro"
    }
];

const initialChart = [
    {label:'Clicks',value:1},
    {label:'Impressions',value:1}
];

class Report extends React.Component{

    state={
        tableData: initialTable,
        chartData: initialChart,
        tableColumns: initialColumns,
        reportPeriod:[new Date(),new Date()],
        isLoading:true,
        hasError: false
    }

    toSQLDate = (date) => {
        let year, month, day;
        year = String(date.getFullYear());
        month = String(date.getMonth() + 1);
        if (month.length === 1) {
            month = "0" + month;
        }
        day = String(date.getDate());
        if (day.length === 1) {
            day = "0" + day;
        }
        return year + "-" + month + "-" + day;
    }

    getReport= (id,date) => {
        let postData={
            from: this.toSQLDate(date[0]),
            to: this.toSQLDate(date[1])
        }
        fetch(basePath + '/report/'+id,{
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer '+ localStorage.getItem('authToken'),
              ...cacheHeaders
            },
            body: JSON.stringify(postData)
          })
          .then(res => res.ok ? res.json() : Promise.reject(new Error(res.status)) )
        .then(data => {
            let reportData = [{}],chartData = [],columnData =[];
            let nullArray = true;
            let sum = 0, cro=0;
            if(data && data.length !== 0){
                data.forEach((elem,i) => {
                    nullArray = nullArray && !elem.metric;
                    reportData[0][elem.type] = elem.metric?elem.metric:0;
                    chartData[i] = {label:elem.type,value:elem.metric?elem.metric:0}
                    columnData[i] = {Header:elem.type,accessor:elem.type}
                });
                if(nullArray){
                    reportData = initialTable;
                    chartData = initialChart;
                    columnData =initialColumns;   
                }else{
                    Object.keys(reportData[0]).forEach((key,i) => {
                        if(key !== 'Impressions')
                        sum += +reportData[0][key];
                    });
                    cro = ((sum/+reportData[0]['Impressions']) * 100).toFixed(2);
                    reportData[0]['clicks'] = sum;
                    reportData[0]['cro'] = cro;                   
                    columnData[columnData.length] = {Header: "Total Clicks",accessor: "clicks"};
                    columnData[columnData.length] = {Header: "CRO (%)",accessor: "cro"};
                }
                this.setState({tableData:reportData,chartData:chartData,tableColumns:columnData,isLoading:false});
            }
        })
        .catch(err => {
            if(+err.message && +err.message > 400 && +err.message < 500){
                M.toast({html: 'Authentication Required', classes: 'rounded ToastFailure'});
                this.props.history.push('/login');
            }else{
                M.toast({html: 'An Error Occurred', classes: 'rounded ToastFailure'});
                this.setState({hasError:true});
            }
        });
    }

    dateHandler = (reportRange) =>{
        this.setState({reportPeriod:reportRange});
        this.getReport(this.props.compId,reportRange);
    }

    componentDidMount(){
        this.getReport(this.props.compId,this.state.reportPeriod);
    }

    render(){
        
        let content = <Spinner />
        if(!this.state.isLoading){
            if(!this.state.hasError && this.state.tableData.length !==0){
                content = <CustomTable columns={this.state.tableColumns} data={this.state.tableData} responsive />                
            }else{
                content = <h3>Data Not Available</h3>
            }
        }

        return (
            <>        
                <div className='ReportControl'>
                    <Link to='/dashboard'>
                        <button className='waves-effect waves-light btn BackBtn'>
                            <i className="material-icons left">arrow_back</i>
                            Go Back
                        </button>
                    </Link>
                    <h4>{this.props.compName}</h4>
                    <DateRangePicker
                        className='card-panel DateRangePicker'
                        onChange={this.dateHandler}
                        value={this.state.reportPeriod}
                    />
                </div>
                <div className='ReportTable card-panel'>
                    {content}
                    <div className='ReportChart'>
                        <DonutChart 
                         data={this.state.chartData}
                         width={700}
                         innerRadius={0.6}
                         colors={['#f44336', '#e91e63', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722', '#795548', '#607d8b' ]}
                         />
                    </div>                    
                </div>
            </>
    );
    }
}

export default Report;