import React from 'react';

import './App.css';
import 'materialize-css/dist/css/materialize.min.css';
import logo from './assets/images/BreezeMaxWebLogo.png';
import Login from './login/login';
import Report from './report/report';
import DashBoard from './dashboard/dashboard';
import ProtectedRoute from './customComponents/protectedRoute';
import ErrorPage from './customComponents/ErrorPage';
import {Route,Switch,Redirect} from 'react-router-dom';

class App extends React.Component {

  state={
    compId:1,
    compName:'Test Company',
    isAuthenticated:localStorage.getItem('authToken') !== null
  }

  selectedCompanyHandler = (id,name) => {
    this.setState({compId:id,compName:name});
  }

  setAuthStatus = (auth) => {
    this.setState({isAuthenticated:auth});
  }

  logoutHandler = () => {
    localStorage.removeItem('authToken');
    this.setState({isAuthenticated:false});
  }

  render(){
    let logOutClass = ['btn-flat', 'waves-effect', 'LogoutBtn'];
    if(!this.state.isAuthenticated){
      logOutClass.push('hideBtn');
    }
    return (
      <div className="App">
        <header className='ReportHeader ShadowBox' >
          <div className='ReportContent'>
            <img src={logo} alt='BreezeMaxWeb' width='120' height='120' />
            <span>BreezeTask Report</span>
          </div>        
          <button onClick={this.logoutHandler} className={logOutClass.join(' ')}>Logout
            <i className='material-icons right'>logout</i>
          </button>
        </header>
        <div className='ReportContainer ShadowBox'>
          <Switch>
            <Redirect from='/' exact to='/dashboard' />
            <Route path='/login' exact render={(props) => <Login {...props} authenticate={this.setAuthStatus} />}  />            
            <ProtectedRoute path='/dashboard/report' exact Component={Report} isAuthenticated={this.state.isAuthenticated} compId={this.state.compId} compName={this.state.compName} />
            <ProtectedRoute path='/dashboard' exact Component={DashBoard} isAuthenticated={this.state.isAuthenticated} viewReport={this.selectedCompanyHandler} />
            <Route component={ErrorPage} />
          </Switch>
        </div>
      </div>
  );
  }
}

export default App;
