import React from 'react'
import { CSVLink } from 'react-csv'

const ExportToCSV = ({csvData, fileName}) => {
    const headers = [
        {label: "Company ID", key: "comp_id"},
        {label: "Company Name", key: "comp_name"},
        {label: "Main Color", key: "main_color"},
        {label: "Products", key: "products"}
    ];
    return (      
            <CSVLink data={csvData} filename={fileName} headers={headers} separator={","}>
                <button className='btn waves-effect waves-light tooltipped' data-tooltip='Export To Excel' >
                    <i className='material-icons'>grid_on</i>
                </button>
            </CSVLink>
    )
}

export default ExportToCSV;